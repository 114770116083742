import React, { forwardRef, Fragment, useContext, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { Menu, Transition, Disclosure } from '@headlessui/react'
import MainNavItem from '../../elements/nav/MainNavItem'
import Logo from '../../elements/Logo'
import Avatar from '../../elements/Avatar'
import Button from '../../elements/Button'
import { useRouter } from 'next/router'
import { useSession, signOut } from 'next-auth/react'
import { routes } from '../../../utils/routes'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { appContext } from '../../../state/AppContext'
import Can from '../../elements/auth/Can'
import { useForm } from 'react-hook-form'
import { mainNavigation } from '../../../utils/navigation'

const noSessionNavigation = [
    { name: 'Inloggen', href: routes.signIn.path },
    { name: 'Aanmelden', href: routes.register.path },
]

const userNavigation = [
    { name: 'profile', href: routes.profile.path, roles: routes.profile.roles },
    { name: 'application-management', href: routes.adminDashboard.path, roles: routes.organisations.roles },
    { name: 'organisation-management', href: routes.adminDashboard.path, roles: routes.myOrganisation.roles },
]

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
}
type AnchorProps = React.HTMLProps<HTMLAnchorElement>
const MyLink = forwardRef<HTMLAnchorElement, AnchorProps>(({ href, children, ...rest }, ref) => {
    return (
        <Link href={href ? href : ''}>
            <a ref={ref} {...rest}>
                {children}
            </a>
        </Link>
    )
})

type FormData = {
    search: string
}

const NavBar: React.FC = () => {
    const { t } = useTranslation(['common', 'search'])
    const { state } = useContext(appContext)
    const router = useRouter()
    const { q, provider } = router.query
    const { status } = useSession()

    const {
        register,
        handleSubmit,
        trigger,

        formState: { errors, isSubmitting },
    } = useForm<FormData>({
        mode: 'onChange',
        defaultValues: {
            search: q ? q.toString() : '',
        },
    })

    const onSubmit = async (data: FormData) => {
        if (isSubmitting) return

        const searchParams = new URLSearchParams()
        if (data.search && data.search !== '') searchParams.append('q', data.search)

        searchParams.append('order', 'relevance')
        if (provider) {
            if (typeof provider === 'string') {
                searchParams.append('provider', provider)
            } else {
                provider.forEach((item) => searchParams.append('provider', item))
            }
        }

        if (router.pathname === routes.trainings.path) {
            await router.replace(routes.trainings.path + '?' + searchParams)
        } else {
            await router.push(routes.trainings.path + '?' + searchParams)
        }
    }

    useEffect(() => {
        trigger().catch(() => {})
    }, [])

    return (
        <>
            {status && (
                <Disclosure
                    as="nav"
                    // className="box-border flex fixed inset-x-0 top-0 z-50 items-center bg-white shadow h-[var(--dann-navbar-height-lg)]"
                    className=" border-b border-gray-200 bg-white inset-x-0 top-0 z-30 fixed h-[var(--dann-navbar-height-lg)]"
                >
                    {({ open }) => (
                        <>
                            <div className="mx-auto w-full px-4  ">
                                <div className="flex justify-between h-[var(--dann-navbar-height-lg)]">
                                    <div className="flex">
                                        <div className="flex justify-center w-full lg:justify-start">
                                            {/* Start logo */}
                                            {/*<div className="absolute top-1/2 -translate-y-1/2 left-0 flex flex-shrink-0 items-center lg:w-[var(--dann-sidebar-width-lg)] justify-center lg:relative lg:justify-start px-4">*/}
                                            <div className="flex items-center">
                                                {router.pathname === routes.index.path ? (
                                                    <a href="https://dann.nl/">
                                                        <Logo className="inline-block h-[calc(var(--dann-navbar-height-lg)-_16px)]" />
                                                    </a>
                                                ) : (
                                                    <Link href="/">
                                                        <a>
                                                            <Logo className="inline-block h-[calc(var(--dann-navbar-height-lg)-_16px)]" />
                                                        </a>
                                                    </Link>
                                                )}
                                            </div>
                                            {/* End logo */}

                                            {/* Start main menu */}
                                            <div className="hidden px-5 mx-5 lg:flex sm:space-x-8">
                                                {mainNavigation.map((item) => (
                                                    <Can roles={item.roles} key={item.name}>
                                                        <MainNavItem
                                                            key={item.name}
                                                            href={item.href}
                                                            name={t(item.name)}
                                                        />
                                                    </Can>
                                                ))}
                                            </div>
                                            {/* End main menu */}
                                        </div>
                                    </div>
                                    <div className="flex flex-grow justify-end">
                                        {/*{status === 'authenticated' && (*/}
                                        <div className="flex flex-grow items-center justify-end">
                                            <div className="flex-grow max-w-2xl ml-8">
                                                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                                                    <label
                                                        htmlFor="default-search"
                                                        className="mb-2 text-sm font-medium text-gray-900 sr-only "
                                                    >
                                                        {t('search')}
                                                    </label>
                                                    <div className="relative">
                                                        <div className="hidden lg:flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                                            <svg
                                                                aria-hidden="true"
                                                                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input
                                                            {...register('search')}
                                                            type="search"
                                                            className="block px-4 py-3 pr-16 lg:pr-64 lg:pl-10 h-full w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-crusta-500 focus:border-crusta-500 "
                                                            placeholder={t('search-placeholder')}
                                                            required
                                                        />

                                                        <Button
                                                            type="submit"
                                                            variant={errors.search ? 'tertiary' : 'primary'}
                                                            className="absolute bottom-1 right-1"
                                                        >
                                                            <span className="hidden lg:block">{t('search')}</span>
                                                            <svg
                                                                aria-hidden="true"
                                                                className="lg:hidden w-5 h-5 "
                                                                fill="none"
                                                                stroke="currentColor"
                                                                viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                                />
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        {status === 'authenticated' ? (
                                            <div className="hidden items-center space-x-3 lg:flex">
                                                {/* Start profile dropdown */}
                                                <Menu as="div" className="relative">
                                                    <div>
                                                        <Menu.Button className="flex ml-8 text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-crusta-500">
                                                            <span className="sr-only">{t('open-menu')}</span>
                                                            <Avatar
                                                                name={
                                                                    state.userState.user?.firstName +
                                                                    ' ' +
                                                                    state.userState.user?.lastName
                                                                }
                                                                size="m"
                                                                notification={false}
                                                            />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-200"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="absolute divide-y divide-gray-100 right-0 mt-2 w-56 overflow-hidden bg-white rounded-md ring-1 shadow-lg origin-top-right ring-blacks-50 focus:outline-none">
                                                            <div>
                                                                {userNavigation.map((item) => (
                                                                    <Can roles={item.roles} key={item.name}>
                                                                        <Menu.Item>
                                                                            {({ active }) => (
                                                                                <MyLink
                                                                                    href={item.href}
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-100' : '',
                                                                                        'block px-4 py-2 text-sm text-gray-700 hover:no-underline'
                                                                                    )}
                                                                                >
                                                                                    {t(item.name)}
                                                                                </MyLink>
                                                                            )}
                                                                        </Menu.Item>
                                                                    </Can>
                                                                ))}
                                                            </div>
                                                            <div>
                                                                <Menu.Item key="signout">
                                                                    {({ active }) => (
                                                                        <button
                                                                            onClick={() => {
                                                                                void (async () => {
                                                                                    try {
                                                                                        await signOut()
                                                                                    } catch (e) {
                                                                                        console.log(e)
                                                                                    }
                                                                                })()
                                                                            }}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100' : '',
                                                                                'w-full px-4 py-2 text-left text-sm text-gray-700 hover:text-crusta-500 hover:no-underline cursor-pointer'
                                                                            )}
                                                                        >
                                                                            {t('sign-out')}
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                                {/* End profile dropdown */}
                                            </div>
                                        ) : (
                                            <div className="hidden space-x-3 lg:flex">
                                                <div className="hidden ml-8 lg:flex sm:space-x-8">
                                                    {noSessionNavigation.map((item) => (
                                                        <MainNavItem
                                                            key={item.name}
                                                            href={item.href}
                                                            name={item.name}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        <div className="flex items-center lg:hidden">
                                            {/* Mobile menu button */}
                                            <Disclosure.Button className="inline-flex ml-4 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                <span className="sr-only">Open main menu</span>

                                                {open ? (
                                                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                                                ) : (
                                                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                                )}
                                            </Disclosure.Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="lg:hidden bg-gray-100 shadow-lg">
                                <div className="space-y-1 pt-2 pb-3 px-4">
                                    {mainNavigation.map((item) => (
                                        <Can roles={item.roles} key={item.name}>
                                            <Link href={item.href} key={item.name}>
                                                <Disclosure.Button
                                                    as="a"
                                                    href={item.href}
                                                    className={classNames(
                                                        router.asPath === item.href
                                                            ? '  text-carto-500'
                                                            : 'border-transparent text-gray-600  hover:text-gray-800',
                                                        'block  py-2 text-base font-medium'
                                                    )}
                                                    aria-current={router.asPath === item.href ? 'page' : undefined}
                                                >
                                                    {t(item.name)}
                                                </Disclosure.Button>
                                            </Link>
                                        </Can>
                                    ))}
                                </div>
                                <div className="border-t border-gray-200 pt-4 pb-3">
                                    {status === 'authenticated' ? (
                                        <>
                                            <div className="flex items-center px-4">
                                                <div className="flex-shrink-0">
                                                    {/*<img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />*/}
                                                    <Avatar
                                                        name={
                                                            state.userState.user?.firstName +
                                                            ' ' +
                                                            state.userState.user?.lastName
                                                        }
                                                        size="m"
                                                        notification={false}
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <div className="text-base font-medium text-gray-800">
                                                        {state.userState.user?.firstName}{' '}
                                                        {state.userState.user?.lastName}
                                                    </div>
                                                    <div className="text-sm font-medium text-gray-500">
                                                        {state.userState.user?.email}
                                                    </div>
                                                </div>
                                                {/*<button*/}
                                                {/*    type="button"*/}
                                                {/*    className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
                                                {/*>*/}
                                                {/*    <span className="sr-only">View notifications</span>*/}
                                                {/*    /!*<BellIcon className="h-6 w-6" aria-hidden="true" />*!/*/}
                                                {/*</button>*/}
                                            </div>
                                            <div className="mt-3 space-y-1">
                                                {userNavigation.map((item) => (
                                                    <Can roles={item.roles} key={item.name}>
                                                        <Disclosure.Button
                                                            as="a"
                                                            href={item.href}
                                                            className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                                        >
                                                            {t(item.name)}
                                                        </Disclosure.Button>
                                                    </Can>
                                                ))}

                                                <Disclosure.Button
                                                    key="signout"
                                                    as="button"
                                                    onClick={() => {
                                                        void (async () => {
                                                            try {
                                                                await signOut()
                                                            } catch (e) {
                                                                console.log(e)
                                                            }
                                                        })()
                                                    }}
                                                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 cursor-pointer"
                                                >
                                                    {t('sign-out')}
                                                </Disclosure.Button>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="flex flex-col space-y-4 px-4">
                                            {noSessionNavigation.map((item) => (
                                                <Disclosure.Button key={item.name} as="a" href={item.href}>
                                                    <Button
                                                        label={item.name}
                                                        variant="primary"
                                                        className="block w-full"
                                                    />
                                                </Disclosure.Button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            )}
        </>
    )
}

export default NavBar
