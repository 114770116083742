import { routes } from './routes'
import { Roles } from './roles'

export const mainNavigation = [
    { name: 'start', href: routes.index.path, icon: null, roles: routes.index.roles },
    { name: 'trainings-title', href: routes.trainings.path, icon: null, roles: routes.trainings.roles },
    { name: 'all-tests', href: routes.tests.path, icon: null, roles: routes.tests.roles },
]

export const mainSSONavigation = [
    { name: 'start', href: routes.index.path, icon: null, roles: [Roles.ROLE_USER] },
    { name: 'trainings-title', href: routes.trainings.path, icon: null, roles: [Roles.ROLE_USER] },
    { name: 'all-tests', href: routes.tests.path, icon: null, roles: routes.tests.roles },
]

export const userNavigation = [
    { name: 'profile', href: routes.profile.path, icon: 'UserCircleIcon', roles: routes.profile.roles },
    { name: 'account', href: routes.account.path, icon: 'KeyIcon', roles: routes.account.roles },
]

export const adminNavigation = [
    {
        name: 'dashboard',
        href: routes.adminDashboard.path,
        icon: 'ChartBarIcon',
        roles: routes.adminDashboard.roles,
    },
    {
        name: 'organisations',
        href: routes.organisations.path,
        icon: 'OfficeBuildingIcon',
        roles: routes.organisations.roles,
    },
    {
        name: 'skills',
        href: routes.skills.path,
        icon: 'PuzzleIcon',
        roles: routes.skills.roles,
    },
    {
        name: 'my-organisation',
        href: routes.myOrganisation.path,
        icon: 'OfficeBuildingIcon',
        roles: routes.myOrganisation.roles,
    },
    {
        name: 'users',
        href: routes.users.path,
        icon: 'UserGroupIcon',
        roles: routes.users.roles,
    },
    {
        name: 'topics',
        href: routes.topics.path,
        icon: 'ColorSwatchIcon',
        roles: routes.topics.roles,
    },
    {
        name: 'training-providers',
        href: routes.trainingProviders.path,
        icon: 'LibraryIcon',
        roles: routes.trainingProviders.roles,
    },
    {
        name: 'shorts',
        href: routes.shorts.path,
        icon: 'PlayIcon',
        roles: routes.shorts.roles,
    },
    {
        name: 'trainings',
        href: routes.trainingsAdmin.path,
        icon: 'AcademicCapIcon',
        roles: routes.trainingsAdmin.roles,
    },
    {
        name: 'startscreen-management',
        href: routes.startPageManagement.path,
        icon: 'TemplateIcon',
        roles: routes.startPageManagement.roles,
    },
    {
        name: 'questionnaires',
        href: routes.questionnairesAdmin.path,
        icon: 'QuestionMarkCircleIcon',
        roles: routes.questionnairesAdmin.roles,
    },
    {
        name: 'toolproviders',
        href: routes.toolProviders.path,
        icon: 'CogIcon',
        roles: routes.toolProviders.roles,
    },
]

export const footerNavigation = [
    // Gebruiksvoorwaarden aanpassen
    { name: 'Gebruiksvoorwaarden', href: routes.terms.path, icon: null, roles: routes.terms.roles },
    { name: 'Privacy statement', href: 'https://dann.nl/privacy-statement', icon: null, roles: routes.index.roles },
    {
        name: 'Over de Digitale Academie',
        href: 'https://dann.nl/over-digitale-academie',
        icon: null,
        roles: routes.index.roles,
    },
    {
        name: 'Samen digitaal sterk',
        href: 'https://dann.nl/over-digitale-academie/samen-digitaal-sterk-met-toeleiders',
        icon: null,
        roles: routes.index.roles,
    },
]
